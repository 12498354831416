import styled from 'styled-components';

export const SectionContainer = styled.div<{ isArabic?: boolean }>`
  padding: 2rem 4rem;
  max-width: 1200px;
  margin: 0 auto;
  text-align: ${(props) => (props.isArabic ? 'right' : 'left')};
`;

export const TitleContainer = styled.div<{ isArabic?: boolean }>`
  text-align: ${(props) => (props.isArabic ? 'right' : 'left')};
  padding: 0;
`;

export const ContentContainer = styled.div<{ isArabic?: boolean }>`
  direction: ${(props) => (props.isArabic ? 'rtl' : 'ltr')};
  text-align: ${(props) => (props.isArabic ? 'right' : 'left')};
`;

export const CountryTitle = styled.h2<{ isArabic?: boolean }>`
  padding: 0;
  font-family: 'Inter SemiBold';
  font-size: 32px;
  font-weight: 900;
  color: black;
  margin-bottom: 1rem;
  text-align: ${(props) => (props.isArabic ? 'right' : 'left')};
`;

export const LastUpdateLabel = styled.p<{ isArabic?: boolean }>`
  font-family: 'Inter SemiBold';
  font-size: 24px;
  color: black;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  text-align: ${(props) => (props.isArabic ? 'right' : 'left')};
`;

export const LegalHeader = styled.h1<{ isArabic?: boolean }>`
  font-family: 'Inter SemiBold';
  font-size: 32px;
  color: black;
  text-align: ${(props) => (props.isArabic ? 'right' : 'left')};
  padding: 0;
`;

export const LegalTitle = styled.h2<{ isArabic?: boolean }>`
  padding: 0;
  font-family: 'Inter SemiBold';
  font-size: 16px;
  font-weight: bold;
  color: black;
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-align: ${(props) => (props.isArabic ? 'right' : 'left')};
`;

export const StyledHr = styled.hr`
  width: 100%;
  margin: 2rem auto;
  border: none;
  border-top: 1px solid #000;
`;

export const KeypointsContainer = styled.div`
  border: 1px solid #000;
  padding: 1rem;
  margin-bottom: 2rem;
`;
